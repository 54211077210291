<template>
  <div class="row">
    <div class="col-12 col-md-10 col-sm-10 col-lg-10 offset-sm-1 offset-lg-1">
      <span
        role="button"
        @click="returned()"
        class="svg-icon svg-icon-1hx pointer fs-base"
      >
        {{ "&#10094; Volver" }}
      </span>
      <h1 class="fs-1 fw-bold mt-8 mb-7 ml-xs-7">Agenda tu cita</h1>

      <div class="card mb-15">
        <div class="bg-dark-site rounded-top">
          <div class="float-start pt-4 pl-4">
            <div class="px-4">
              <h2 class="fs-2 fw-bold">
                {{ vehicle.brand }} | {{ vehicle.model }} | {{ vehicle.version }} | {{ vehicle.year }}
              </h2>
            </div>
          </div>

          <div class="float-end pt-4 pr-4">
            <div class="px-4">
              <h5 class="fs-2 fw-bold">Cita en agencia</h5>
            </div>
          </div>
        </div>

        <!-- outline-gray-lighten -->
        <div class="card-body rounded-bottom bg-gray-site">
          <div class="row">
            <div class="col-4 col-md-4 col-sm-4 col-lg-4">
              <h5 class="fs-2 fw-bold">{{ customer.City }}</h5>
              <br>
              
              <!-- <h2 class="fs-2">Escoge la agencia</h2> -->

              <!-- <br> -->
              <!-- <div class="input-group col-md-4">
                  <input class="form-control py-2 border-right-0 outline-gray-lighten" type="search" name="search-agency" id="search-agency">
                  <span class="input-group-append">
                    <button class="btn btn-outline-secondary border-left-0 outline-gray-lighten" type="button">
                      <i class="fa fa-search"></i>
                    </button>
                  </span>
                </div> -->
              <!-- <el-input class="w-100" clearable placeholder="Buscar una agencia">
                  <template #suffix>
                    <img class="el-input__icon me-5 p-1" style="cursor:pointer" src="media/icons/iconsax/search-normal.svg" />
                  </template>
                </el-input> -->

              <!-- <h5 class="mt-5 px-1">Estado</h5> -->
              <el-select class="fs-1 fw-bold w-100 pe-1 order" effect="dark" placeholder="Selecciona una agencia" v-model="agency_id" @change="getAgency(agency_id)">
                <template v-for="agency in agencies">
                  <el-option v-if="agency.cita" :key="agency.id" :value="agency.id" :label="agency.name">{{ agency.name }}</el-option>
                </template>
              </el-select>
            </div>

            <div class="col-8 col-md-8 col-sm-8 col-lg-8 rounded">
              <div ref="mapDiv" class="rounded" style="height: 450px"></div>
            </div>
          </div>

          <div class="row pt-10">
            <div class="col-md-12 text-right">
              <button @click="save()" type="button" class="btn btn-primary float-end">
                <span class="fs-base">Siguiente</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import { computed, defineComponent, onMounted, ref, onBeforeMount, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { Loader } from "@googlemaps/js-api-loader";

const API_KEY = "AIzaSyACDXm5WBEngJZHLoEiSzzYCdlkg3THQmI";

export default {
  name: "AgencyAppointment",
  components: {},
  setup(props) {
    const route = useRoute();
    const router = useRouter();

    const loader = new Loader({ apiKey: API_KEY });
    const agencies = ref([]);
    const mapDiv = ref(null);
    const map = ref();
    const coordinates = ref(null);
    const agency_id = ref(null);

    const vehicle = ref({
      id: "",
      brand: "",
      model: "",
      version: "",
      year: "",
    });

    const customer = ref({
      Email: "",
      Names: "",
      Phone: "",
      Surnames: "",
      Address: "",
      Colony: "",
      City: "",
      State: "",
      idState: "",
      idCity: "",
    });

    const agency = ref({
      id: "",
      address: "",
      city: "",
      colonia: "",
      cp: "",
      email: "",
      id_state: "",
      latitude: "",
      longitude: "",
      name: "",
      phone: "",
      state: "",
      url_whatsapp: "",
    });

    const getAgencies = async (city = "") => {
      try {
        const { data } = await ApiService.get("/api/site/agencies?showing=100&city=" + city);
        agencies.value = data.data.agency.data;
        // console.log(data);
        // console.log(city);
      } catch (error) {
        console.log(error);
      }
    };

    const getAgency = (value) => {
      for (let row of agencies.value) {
        if (value == row.id) {
          agency.value = row;
          return agency;
        }
      }
    };

    const returned = () => {
        router.go(-1);
    };

    const save = async () => {
      if (!agency_id.value) {
        Swal.fire({
          text: "Selecciona una agencia",
          icon: "warning",
        });

        return false;
      }

      let appointment_data = {
        tipo: 1,
        fecha: "",
        nombre: customer.value.Names,
        apellidos: customer.value.Surnames,
        email: customer.value.Email,
        phone: customer.value.Phone,
        vehicle_client: vehicle.value.id,
        agency: agency.value.name,
        agency_id: agency_id.value,
      };

      // console.log(appointment_data);
      localStorage.setItem("appointment_agency", JSON.stringify(agency.value));
      localStorage.setItem("appointment_data", JSON.stringify(appointment_data));

      router.push("seleccionar-cita");

      // console.log("Test");
      // router.push('confirmar-cita');
    };

    onBeforeMount(async () => {
      
    });

    onMounted(async () => {
      await loader.load();
      
      customer.value = JSON.parse(localStorage.getItem("appointment_customer") || "{}");
      vehicle.value = JSON.parse(localStorage.getItem("appointment_vehicle") || "{}");
      await getAgencies(customer.value.City);

      map.value = new google.maps.Map(mapDiv.value, {
        center: { lat: 20.724671, lng: -103.3471888 },
        // center:{ lat:0, lng:0 },
        zoom: 6,
      });

      var geocoder = new google.maps.Geocoder();

      geocoder.geocode({ address: customer.value.City }, function (results, status) {
        if (status === "OK") {
          map.value.setCenter(results[0].geometry.location);
        } else {
          Swal.fire({
            text: "Geocode was not successful for the following reason: " + status,
            icon: "warning",
          });
        }
      });

      agencies.value.forEach((agency) => {
        // console.log(agency);

        if (agency.latitude && agency.longitude && agency.cita) {
          const marker = new google.maps.Marker({
            position: {
              lat: parseFloat(agency.latitude),
              lng: parseFloat(agency.longitude),
            },
            title: agency.name,
            map: map.value,
            icon: {
              scaledSize: new google.maps.Size(32, 32),
              url: "media/icons/iconsax/location-red.png",
            },
          });

          // map.value.panTo({ lat:parseFloat(agency.latitude), lng:parseFloat(agency.longitude) });

          const infowindow = new google.maps.InfoWindow({
            content: `<div id="content" class="content_marker px-4">
                    <div id="bodyContent">
                      <h5 class="firstHeading">${agency.name}</h5>
                      <p class="">${agency.address}, ${agency.city}, ${agency.state}</p>
                    </div>
                  </div>`,
          });

          marker.addListener("mouseover", function () {
            infowindow.open(map, this);
          });

          // marker.addListener("mouseout", function() {
          //   infowindow.close();
          // });
        } else {
          //@Todo crear un enpoint para recuperar las coordenadas en base a la direccion y guardar los cambios en base de datos
        }
      });
    });

    return {
      getAgencies,
      mapDiv,
      vehicle,
      save,
      agencies,
      agency_id,
      getAgency,
      customer,
      returned
    };
  },

  // methods:{
  //     save() {
  //       router.push('seleccionar-cita');
  //   },
  // }
};
</script>

<style lang="scss">
.el-pagination.is-background .btn-next:disabled,
.el-pagination.is-background .btn-prev:disabled {
  background-color: transparent;
}
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background-color: transparent;
}
.el-pagination {
  --el-pagination-bg-color: transparent;
  --el-pagination-button-disabled-color: transparent;
}
.el-input__icon {
  width: 24px;
  color: lightgray;
}
.el-input {
  --el-input-hover-border-color: #393840;
  --el-input-border-color: #393840;
  --el-input-bg-color: transparent;
  --el-input-text-color: white;
}
.order .el-input {
  --el-input-placeholder-color: #fff;
}
.el-input__wrapper {
  padding: 0.5em 1em;
}
.grid-button-active {
  background: #3699ff;
  color: white;
}
.btn-icon.grid-btn:not(.grid-button-active) {
  border: 1px solid rgba(255, 255, 255, 0.212) !important;
  color: white;
}
.order .el-input__wrapper {
  box-shadow: 0 0 0 0px;
  &:hover {
    box-shadow: 0 0 0 0px;
  }
  &:active {
    box-shadow: 0 0 0 0px;
  }
}
.order .el-input__inner {
  --el-input-text-color: #3699ff;
}
.el-select.order {
  --el-select-placeholder-color: #fff;
  --el-select-input-color: #fff;
  --el-select-border-color-hover: transparent;
  --el-select-input-focus-border-color: transparent;
}
.order.el-input {
  --el-select-placeholder-color: #fff;
}
.el-select__popper.el-popper[role="tooltip"] {
  background: #393840f0;
  border: 1px solid gray;
  box-shadow: var(--el-box-shadow-light);
}
.el-popper__arrow::before {
  background: #393840f0 !important;
}
.el-select-dropdown__item {
  color: white;
  &:hover {
    background: #555458f0 !important;
  }
  &.selected {
    background: #555458f0 !important;
  }
  &.hover {
    background: #555458f0 !important;
  }
}
.order .el-input__inner {
  color: #3699ff;
}
.filt {
  top: 100px;
}

.gm-style,
.gm-style-iw-c,
.gm-style-iw-t,
.gm-style-iw-t::after,
.content_marker {
  background-color: #393840 !important;
}

.gm-style-iw-d {
  overflow: hidden !important;
}
</style>
